import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';

import Layout from '../../components/layout';
import Menu from '../../components/menuComponent';
import WorkWrapper from '../../components/workWrapper';

const WorkContainer = ({ location }) => {
  const { pathname } = location;
  const filter = pathname.split('/').filter((pathString) => pathString.length > 0).pop();

  return (
    <Layout
      title="Fitted Bedrooms | Wow Interior Design"
      description="Examples of some of the bedrooms Wow Interior Design have created. Choose from our wide selection of bedroom ranges or Wow can help you create your own specific style."
    >
      <Menu />
      <WorkWrapper
        filter={filter}
        random={get(location, 'state.random', false)}
      />
    </Layout>
  );
};

export default WorkContainer;

WorkContainer.propTypes = {
  location: PropTypes.object.isRequired,
};
